const abbrevToStation = {
  CL: 'Clinton',
  DR: 'Dresden',
  FP: 'FitzPatrick',
  LS: 'LaSalle',
  LM: 'Limerick',
  NM: 'Nine Mile Point',
  PB: 'Peach Bottom',
  QC: 'Quad Cities'
}

const zip = (a, b) => a.map((k, i) => [k, b[i]]);
const ignoreKeys = [
  'pin_by_pin_enrichment',
  'pin_by_pin_gad_concentration'
]

const needKeys = ['CYEXP', 'TFW', 'RP', 'WCT']

const intFeatureVals = (hdf, feature) => (
  hdf.keys
    .filter(x => needKeys.every(y => hdf.get(x).keys.includes(y)))
    .map(k => parseInt(hdf.get(k).get(feature).value))
)

const floatFeatureVals = (hdf, feature) => {
  return hdf.keys
    .filter(x => needKeys.every(y => hdf.get(x).keys.includes(y)))
    .map(k => +(hdf.get(k).get(feature).value))
}

const genericFeatureVals = (hdf, feature) => (
  hdf.keys.map(k => hdf.get(k).get(feature).value)
)

const intFeatureLine = (hdf, feature) => (
  zip(intFeatureVals(hdf, feature), intFeatureVals(hdf, 'CYEXP'))
    .filter(d => d[0] > 0)
)

const floatFeatureLine = (hdf, feature) => (
  zip(floatFeatureVals(hdf, feature), intFeatureVals(hdf, 'CYEXP'))
    .filter(d => d[0] > 0)
)

const genericFeatureLine = (hdf, feature) => (
  zip(genericFeatureVals(hdf, feature), intFeatureVals(hdf, 'CYEXP'))
    .filter(d => d[0] > 0)
)

const rodPatternChanges = (hdf) => {
  const patterns = genericFeatureLine(hdf, 'NOTCH')
  const patternChanges = []
  for (let i = 1; i < patterns.length; i++) {
    if (JSON.stringify(patterns[i][0]) !==
      JSON.stringify(patterns[i - 1][0])) {
      patternChanges.push(patterns[i])
    }
  }
  const longPatternChanges = []
  for (let i = 0; i < patternChanges.length - 1; i++) {
    if (patternChanges[i + 1][1] - patternChanges[i][1] > 150) {
      longPatternChanges.push(patternChanges[i])
    }
  }
  return longPatternChanges.map(x => x[0])
}

const eigRodPatternChanges = (hdf) => {
  const keys = hdf.keys
    .filter(x => !ignoreKeys.includes(x))
    .filter(x => needKeys.every(y => hdf.get(x).keys.includes(y)))
  const patterns = zip(
    keys.map(k => hdf.get(k).get('rod_pattern').value),
    keys.map(k => parseInt(hdf.get(k).get('core_average_exposure_mwdst').value))
  ).filter(d => d[0] > 0)
  const patternChanges = []
  for (let i = 1; i < patterns.length; i++) {
    if (JSON.stringify(patterns[i][0]) !==
      JSON.stringify(patterns[i - 1][0])) {
      patternChanges.push(patterns[i])
    }
  }
  const longPatternChanges = []
  for (let i = 0; i < patternChanges.length - 1; i++) {
    if (patternChanges[i + 1][1] - patternChanges[i][1] > 150) {
      longPatternChanges.push(patternChanges[i])
    }
  }
  return longPatternChanges.map(x => x[0])
}

const buildTitleFromId = cycleId => {
    const stationPart = abbrevToStation[cycleId.substring(0, 2)]
    const unitPart = cycleId.substring(2, 3)
    const cyclePart = cycleId.substring(4, 6)
    return stationPart + ' ' + unitPart + ', Cycle ' + cyclePart
}

export {
  intFeatureVals,
  floatFeatureVals,
  intFeatureLine,
  floatFeatureLine,
  rodPatternChanges,
  eigRodPatternChanges,
  buildTitleFromId
}