import * as hdf5 from 'jsfive/index.js'
import stationInfo from 'static/station_info.json'
import moment from 'moment-timezone'

const features = [
  'CYEXP',
  'NOTCH',
  'P',
  'PAXIAL',
  'PB',
  'RP',
  'VF',
  'W',
  'WCT',
  'XQEX'
]

const eigDepletionFeatures = [
  'axial_power_shape',
  'bundle_flow_lbmhr',
  'core_average_exposure_mwdst',
  'eigenvalue',
  'nodal_relative_power',
  'nodal_void_fraction',
  'radial_peaking_factor',
  'rod_pattern',
  'total_core_flow_mlbmhr',
  'total_core_power_mwth',
]

const eigHistoricFeatures = [
  'axial_power_shape',
  'bundle_flow_lbmhr',
  'core_average_exposure_mwdst',
  'eigenvalue',
  'nodal_relative_power',
  'nodal_void_fraction',
  'radial_peaking_factor',
  'rod_pattern',
  'total_core_flow_mlbmhr',
  'total_core_power_mwth',
]

const eigIgnore = [
  'pin_by_pin_enrichment',
  'pin_by_pin_gad_concentration'
]

const months = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'Aug',
  9: 'Sept',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
}

const add = (a, b) => a + b
const mean = array => array.reduce(add) / array.length

const customParseDate = num => {
  const string = num.toString()
  const month = months[parseInt(string.substring(2, 4))]
  const day = string.substring(4, 6)
  const year = '20' + string.substring(0, 2)
  return month + ' ' + day + ', ' + year
}

const featureFilename = (unit, cycle, feature) => {
  const stationString = (unit === '1') ? 'HT1_' : 'HV1_'
  const cycleString = 'C' + cycle.toString().padStart(2, '0') + '_'
  return stationString + cycleString + feature + '.DAT'
}

const extractStatePoints = fileText => {
  return (
    fileText
      .split('\n')
      .filter(x => x.charAt(0) === '(')
      .map(x => x.split(';')[1].split('#')[0])
  )
}

const buildReturnString = allMissingPoints => {
  const featureStrings = (
    Object.keys(allMissingPoints)
      .filter(key => allMissingPoints[key].length > 0)
      .map(key => (
        key + '(' + allMissingPoints[key].length + ')'
      ))
  )
  return featureStrings.join(', ')
}

export const reviewDepletionZip = async (zip, unit, cycle) => {
  const refFilename = featureFilename(unit, cycle, 'CYEXP')
  console.log(refFilename)
  const refFileText = await zip.file(refFilename).async('text')
  const refPoints = extractStatePoints(refFileText)
  const allMissingPoints = {}
  for (const feature of features) {
    const filename = featureFilename(unit, cycle, feature)
    const fileText = await zip.file(filename).async('text')
    const points = extractStatePoints(fileText)
    const missingPoints = refPoints.filter(x => !points.includes(x))
    if (missingPoints.length) {
      allMissingPoints[feature] = missingPoints
    }
  }
  return (
    (Object.keys(allMissingPoints).length)
      ? buildReturnString(allMissingPoints)
      : null
  )
}

export const reviewDepletionHdf = hdf => {
  console.log('reviewing hdf file')
  console.log(hdf)
  const allMissingFeatures = features.reduce((a,x) => ({...a, [x]: []}), {})
  allMissingFeatures.points = hdf.keys.length
  hdf.keys.forEach(key => {
    const pointFeatures = hdf.get(key).keys
    const missingFeatures = features.filter(x => !pointFeatures.includes(x))
    missingFeatures.forEach(x => allMissingFeatures[x].push(key))
  })
  return allMissingFeatures
}

export const reviewEigDepletionHdf = hdf => {
  console.log('reviewing hdf file')
  console.log(hdf)
  const allMissingFeatures = eigDepletionFeatures.reduce(
    (a,x) => ({...a, [x]: []}), {}
  )
  allMissingFeatures.points = hdf.keys.length
  hdf.keys.filter(key => !eigIgnore.includes(key))
    .forEach(key => {
      console.log(key)
      const pointFeatures = hdf.get(key).keys
      console.log(pointFeatures)
      const missingFeatures = eigDepletionFeatures.filter(
        x => !pointFeatures.includes(x)
      )
      missingFeatures.forEach(x => allMissingFeatures[x].push(key))
    })
  return allMissingFeatures
}


export const reviewHistoricHdf = hdf => {
  const points = hdf.keys.length
  const exposureVals = hdf.keys
    .map(k => parseInt(hdf.get(k).get('CYEXP').value))
  const exposureMin = Math.min(...exposureVals)
  const exposureMax = Math.max(...exposureVals)
  const exposureRange = exposureMin + ' - ' + exposureMax
  const mcoVals = hdf.keys.map(k => parseFloat(hdf.get(k).get('MCO').value))
      .filter(d => d > 0)
  const hasMco = mcoVals.length > 0
  const mcoMin = (hasMco) ? Math.min(...mcoVals) : ''
  const mcoMax = (hasMco) ? Math.max(...mcoVals) : ''
  const mcoRange = mcoMin + ' - ' + mcoMax
  const mcoAvg = mean(mcoVals).toFixed(4)
  const dateVals = hdf.keys.map(k => parseInt(hdf.get(k).get('DATE').value))
  const dateMin = customParseDate(Math.min(...dateVals))
  const dateMax = customParseDate(Math.max(...dateVals))
  const dateRange = dateMin + ' - ' + dateMax
  const coverage = (
    Math.min(Math.round((points / 705) * 20) * 5, 100) + '%'
  )
  return{
    dateRange: dateRange,
    statePoints: points,
    cycleCoverage: coverage,
    exposureRange: exposureRange,
    mcoPoints: mcoVals.length,
    mcoRange: mcoRange,
    mcoAverage: mcoAvg
  }
}

const eigDateConvert = dateString => {
  const datePiece = dateString.split('/')
  let yearPiece = +(datePiece[0])
  yearPiece += 2000
  let monthPiece = +(datePiece[1])
  monthPiece = monthPiece - 1
  return moment({
    year: yearPiece,
    month: monthPiece,
    day: +(datePiece[2])
  }).format('MMM D, YYYY')
}

export const reviewEigHistoricHdf = hdf => {
  const points = hdf.keys.filter(x => !eigIgnore.includes(x))
  let exposureVals = points
    .map(k => parseInt(hdf.get(k).get('cycle_exposure_mwdst').value))
  const dateVals = points
    .map(k => hdf.get(k).get('exposure_accounting_datetime').value[0])
    .map(x => x.split(' ')[0])
  console.log(dateVals)

  const exposureMin = Math.min(...exposureVals)
  const exposureMax = Math.max(...exposureVals)
  const exposureRange = exposureMin + ' - ' + exposureMax
  const mcoVals = points.map(
    k => parseFloat(hdf.get(k).get('eigenvalue').value)
  ).filter(d => d > 0)
  const hasMco = mcoVals.length > 0
  const mcoMin = (hasMco) ? Math.min(...mcoVals).toFixed(4) : ''
  const mcoMax = (hasMco) ? Math.max(...mcoVals).toFixed(4) : ''
  const mcoRange = mcoMin + ' - ' + mcoMax
  const mcoAvg = mean(mcoVals).toFixed(4)
  const dateRange = (
    eigDateConvert(dateVals[0]) + ' - ' 
    + eigDateConvert(dateVals.pop())
  )
  const coverage = (
    Math.min(Math.round((points.length / 705) * 20) * 5, 100) + '%'
  )
  return{
    dateRange: dateRange,
    statePoints: points.length,
    cycleCoverage: coverage,
    exposureRange: exposureRange,
    mcoPoints: mcoVals.length,
    mcoRange: mcoRange,
    mcoAverage: mcoAvg
  }
}

export const buildCycleId = (station, unit, cycle) => {
  const stationPart = stationInfo[station]['abbrev']
  const unitPart = unit
  const cyclePart = ('0' + cycle).slice(-2)
  return stationPart + unitPart + 'C' + cyclePart
}
